<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section-first-page-hero">
      <div class="slider">
        <div class="slider-container">
          <picture>
            <source
              srcset="@/assets/i/luggage_480.png"
              style="min-height: 320px"
              media="(max-width: 374px)"
            />
            <source
              srcset="@/assets/i/luggage_480.png"
              style="min-height: 320px"
              media="(max-width: 413px)"
            />
            <source
              srcset="@/assets/i/luggage_768.png"
              style="min-height: 346px"
              media="(max-width: 767px)"
            />
            <source
              srcset="@/assets/i/luggage_768.png"
              style="min-height: 346px"
              media="(max-width: 1023px)"
            />
            <source
              srcset="@/assets/i/luggage_big.png"
              style="min-height: 404px"
              media="(max-width: 1279px)"
            />
            <source
              srcset="@/assets/i/luggage_big.png"
              style="min-height: 404px"
              media="(max-width: 1439px)"
            />
            <source
              srcset="@/assets/i/luggage_big.png"
              style="min-height: 404px"
              media="(max-width: 1599px)"
            />
            <img src="@/assets/i/luggage_big.png" width="100%" alt="" />
          </picture>
        </div>
      </div>
    </section>
    <section class="section section-default section-text">
      <div class="container m-container-only-s">
        <div v-if="$i18n.locale === 'ru'">
          <div class="content loti">
            <h4>
              Полезная информация
            </h4>
            <p
              class="cursive"
              style="
font-size: 20px;"
            >
              Добавьте комфорта вашему полету онлайн на сайте azurair.ru
            </p>

            <h4
              style="
        margin-top:50px;"
            >
              1. Дополнительный багаж
            </h4>
            <div class="columns">
              <div>
                <p class="cursive">
                  Нужно перевезти больше вещей, чем предусмотрено вашим тарифом?
                  Не проблема! Приобретайте дополнительный багаж. За
                  дополнительную плату вы можете перевезти еще одно место
                  багажа. Возможные варианты:
                </p>
                <p class="cursive">• 10 кг</p>
                <p class="cursive">• 20 кг</p>
                <p class="cursive">• 25 кг</p>
                <p class="cursive">• 30 кг</p>
              </div>
              <div>
                <p class="cursive">
                  Габариты багажа не должны превышать 203 см по сумме трех
                  измерений. Объединить дополнительный багаж с бесплатным
                  нельзя, он перевозится в отдельном чемодане.
                </p>
                <p class="cursive">
                  Приобрести услугу можно в «Управлении заказом» на сайте
                  azurair.ru при наличии кода бронирования или номера билета,
                  или в аэропорту вылета.
                </p>
              </div>
            </div>

            <h4
              style="
        margin-top:50px;"
            >
              2. Онлайн-регистрация
            </h4>
            <p class="cursive">
              Воспользуйтесь услугой онлайн-регистрации, чтобы выбрать желаемое
              место на борту самолета – как за дополнительную плату, так и
              бесплатно. К платным относятся места у окна, у прохода и места с
              увеличенным расстоянием для ног AZUR Space.
            </p>
            <p class="cursive">
              Онлайн-регистрация открывается за 24 часа и заканчивается за 3
              часа до вылета рейса. Услуга доступна при вылете из России, Турции
              (Анталья, Даламан), Таиланд (Пхукет).
            </p>
            <p class="cursive">
              Зарегистрироваться онлайн можно на сайте azurair.ru.
            </p>
          </div>
        </div>
        <div v-else>
          <div class="content loti">
            <h4>
              INFORMATION
            </h4>
            <p
              class="cursive"
              style="
font-size: 20px;"
            >
              Add comfort to your flight online at azurair.ru!
            </p>

            <h4
              style="
        margin-top:50px;"
            >
              1. Extra baggage
            </h4>
            <div class="columns">
              <div>
                <p class="cursive">
                  Need to take more stuff than your fare provides? No problem!
                  Purchase an extra baggage. You can carry one more piece of
                  baggage for an additional fee. Options:
                </p>
                <p class="cursive">- 10 kg</p>
                <p class="cursive">- 20 kg</p>
                <p class="cursive">- 25 kg</p>
                <p class="cursive">- 30 kg</p>
              </div>
              <div>
                <p class="cursive">
                  The bag size may not exceed 203 cm on the sum of the three
                  dimensions. An extra baggage cannot be combined with free
                  baggage; it is carried in a separate suitcase.
                </p>
                <p class="cursive">
                  You can buy the service in "My Booking" on azurair.ru with
                  your booking code or ticket number, or at the airport of
                  departure.
                </p>
              </div>
            </div>

            <h4
              style="
        margin-top:50px;"
            >
              2. Online check-in
            </h4>
            <p class="cursive">
              Use the online check-in service to choose your preferred seat,
              either for a fee or free of charge. Paid seats include window
              seats, aisle seats and seats with extra legroom AZUR Space.
            </p>
            <p class="cursive">
              Online check-in opens 24 hours and ends 3 hours before departure.
              The service is available for flights from Russia, Turkey (Antalya,
              Dalaman), Thailand (Phuket).
            </p>
            <p class="cursive">
              Online check-in is available at azurair.ru.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "InfoLoti",
  title() {
    return this.$t("navigation.info_luggage");
  }
};
</script>

<style scoped></style>
